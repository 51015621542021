<template>
  <div>

    <b-modal id="modal-xl" ref="modal1" size="lg" title="Select Image From Media Library">
      <div class="row">
        <div class="col-lg-3 col-md-4 col-6">
          <select class="custom-select form-control" @change="filter" v-model="type">
            <option value="" selected>All media items</option>
            <option value="image">Image</option>
            <option value="doc">Doc</option>
            <option value="video">Video</option>
            <option value="audio">Audio</option>
          </select>
        </div>
        <div class="col-lg-3 col-md-3 col-6">
          <date-picker @change="filter" v-model="date" format="YYYY-MM-DD" placeholder="Select Date" valueType="format">
          </date-picker>
        </div>

        <div class="col-lg-3 col-md-1  d-none-sm"></div>
        <div class="col-lg-3 col-md-4 col-12">
          <div class="d-flex align-items-center input-group search-field">
            <div class="input-group-prepend bg-transparent">
              <i class="input-group-text border-0 mdi mdi-magnify"></i>
            </div>
            <input v-model="searchKey" type="text" v-on:keyup="filter()" class="
                      form-control
                      bg-transparent
                      border-0
                      search-border-left
                    " placeholder="Search...." />
          </div>
        </div>
      </div>
      <form @submit.prevent="imageId">
        <b-form-group class="mb-0">
          <div class="row pl-3 pr-3 pt-2 pb-2 ">
            <div style="    padding: 0.269rem;" v-for="item in info" :key="item.id">
              <input v-model="selected" type="radio" :id=item.id class="input-hidden" :value=item.id />
              <label :for=item.id class="border mb-0" v-if="item.type === 'image'">
                <img :src="`${imageApiURL}/${item.thumb_m}`" class="show-img img-fluid" />
              </label>
              <label :for=item.id class="border mb-0" v-if="item.type !== 'image'">
                <img :src="`${imageApiURL}/${item.thumbs.thumb_m}`" class="show-img img-fluid" /></label>
            </div>
            <div class="col-12 pb-3 mt-2 text-center p-1 " style="border-top: 1px solid #e9ecef" v-if="info == ''">
              <div class="pt-3">
                No data found!
              </div>
            </div>

          </div>
          <div class="" style="border-top: 1px solid #e9ecef">
            <paginate  :page-count="totalPage" :page-range="3" :margin-pages="1"
              :click-handler="clickCallback" :prev-text="'Prev'" :next-text="'Next'" :container-class="'pagination float-left mb-0'"
              :page-class="'page-item'">
            </paginate>
          </div>
          <div class="form-group float-right mt-2 mb-0">
            <button class="btn btn btn-add btn-primary">Upload</button>
          </div>
        </b-form-group>

      </form>
    </b-modal>
  </div>
</template>


 <script>
 import DatePicker from "vue2-datepicker";
 import axios from "axios";
 import Loader from "../components/Loader";
 const imageApiURL = process.env.VUE_APP_IMAGE_URL;
 const mediaApi = process.env.VUE_APP_MEDIA_SERVICE;
 import Paginate from 'vuejs-paginate'
 export default {
   name: 'choose-image',
   components: {
     Loader,
     DatePicker,Paginate
   },
   data() {
     return {
       selected: '',
       info: null,
       totalPage: this.totalPage,
       showLoader: false,
       imageApiURL,
       searchKey: "",
       type: "",
       date: "",
     }
   },
   mounted() {
     let page = 1;
     this.list(page);
     
   },
   methods: {
     list(page) {
       this.showLoader = true;
       axios.get(`${mediaApi}/files?page=${page}`).then((response) => {
         this.info = response.data.data.data;
         this.showLoader = false;
         this.totalPage = response.data.data.last_page;
         this.current_page = response.data.data.current_page;
         
       });
     },
     clickCallback: function (page) {
      this.list(page) 
    },
     imageId() {
       this.$refs.modal1.hide();
       this.$emit("imageId", this.selected);
    
     },
     onClickPage(item) {
       this.list(item);
     },
     isPageActive(item) {
       return this.current_page === item;
     },
     filter(item) {
 
       var parameter = {
         name: this.searchKey, type: this.type, date: this.date
       };
       var text = Object.keys(parameter).forEach(k => (parameter[k] === undefined || parameter[k] === null || parameter[k] === '') && delete parameter[k]);
       var key = new URLSearchParams(parameter).toString()
       this.showLoader = true;
       axios
         .get(`${mediaApi}/search?${key}&page=${item}`)
         .then((response) => {
           this.info = response.data.data.data;
           this.showLoader = false;
           this.totalPage = response.data.data.last_page;
           this.current_page = response.data.data.current_page;
         });
     },
     
   }
 
 }
 </script>
